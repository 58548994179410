<!-- eslint-disable vue/html-self-closing -->
<template>
  <section>
    <b-overlay :show="loading">
      <div v-if="results">
        <b-card>
          <b-card-title
            class="mb-50"
            v-if="
              queryDataType === 'monthlySummaryDayDetailListData' ||
                queryDataType === 'monthlySummaryDayDetailListDataFree'
            "
            >สรุปข้อมูลการขายประจำวัน {{ $date(date).format('DD-MM-YYYY') }} <small>(วัน-เดือน-ปี)</small></b-card-title
          >
          <b-card-title class="mb-50" v-else
            >สรุปข้อมูลการขายประจำเดือน {{ $date(date).format('MM-YYYY') }} <small>(เดือน-ปี)</small></b-card-title
          >
          <b-card-text class="mb-0">{{ results.topic_image_data.name }}</b-card-text>
          <b-card-text> จำนวนดาวน์โหลดทั้งหมด {{ cSumTotalDownload }} รูป </b-card-text>
        </b-card>
        <b-row cols="1" cols-sm="2" cols-lg="3">
          <b-col v-for="(image, index) in results.topic_image_list_datas" :key="index">
            <div class="card px-1 py-2">
              <div class="d-flex justify-content-center mb-1">
                <img :src="image.topic_list_data_image_url" :alt="image.topic_list_data_title" />
              </div>
              <p>ชื่อรูป {{ image.topic_list_data_title || 'ไม่พบชื่อรูปนี้' }}</p>
              <p>จำนวนดาวน์โหลด {{ formatNumberToCurrency(image.topic_list_data_download_total) }} รูป</p>
              <p>
                ยอดขาย {{ formatNumberToCurrency(image.topic_list_data_sale_total) }} เครดิต
                <small class="text-success">(หักส่วนต่างแล้ว)</small>
              </p>
              <div v-if="userInfo.role_data_preview === 'admin'">
                <p v-if="image.user_download.length !== 0">ผู้ใช้ที่ดาวน์โหลด</p>
                <ul v-for="(user, uIndex) in image.user_download" :key="uIndex">
                  <li>{{ user.user_email }}</li>
                </ul>
              </div>
            </div>
            <!-- <pre>{{ image }}</pre> -->
          </b-col>
        </b-row>
      </div>
      <!-- <pre> {{ userInfo }}</pre> -->
      <!-- <pre>queryDataType {{ queryDataType }}</pre> -->

      <!-- <pre>{{ results }}</pre> -->
    </b-overlay>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      results: null,
      loading: false,
      queryDataType: '',
      date: null,
    }
  },
  computed: {
    ...mapState('AuthStore', ['userInfo']),

    cSumTotalDownload() {
      if (this.results && this?.results.topic_image_list_datas.length > 0) {
        let total = 0

        this.results.topic_image_list_datas.forEach(v => {
          total += v.topic_list_data_download_total
        })
        return this.formatNumberToCurrency(total)
      }
      return 0
    },
  },
  mounted() {
    const { topic_image_data_id, date, queryDataType } = this.$route.params
    this.queryDataType = queryDataType
    this.date = date
    this.fetchItem(topic_image_data_id, date, queryDataType)
  },
  methods: {
    async fetchItem(topicImageDataId, date, queryDataType = 'monthlySummaryDayDetailListData') {
      this.loading = true
      const resp = await this.api.post('api/report/sale-info/index', {
        queryDataType,
        date,
        topic_image_data_id: topicImageDataId,
      })
      // console.log('fetchItem', resp)
      this.results = resp
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  width: auto;
  height: 100%;
  max-height: 200px;
  border-radius: 0.5rem;
}
</style>
